<script lang="ts" setup>
import { ref, computed } from 'vue'

import { buildQueryString } from '@/utils/urls'
import NavBar from '@/components/NavBar/index.vue'
import SideMenuMobile from '@/components/SideMenuMobile/index.vue'

const { tm, rt } = useI18n()

const isMenuOpen = ref(false)

const hasCompletedOnboardingCookie = useCookie<boolean>('has_completed_onboarding')
const opportunityTypeCookie = useCookie<string | null>('onboarding_opportunity_type')
const deliveryTermCookie = useCookie<string | null>('onboarding_delivery_term')
const bedroomsNumberCookie = useCookie<number | null>('onboarding_bedrooms_number')

const items = computed(() => (tm('items') as Array<any>).map((item: any) => {
  const id = rt(item.id)
  let path = rt(item.path)

  if (id === 'onboarding') {
    if (hasCompletedOnboardingCookie.value) {
      path += '?' + buildQueryString({
        step: opportunityTypeCookie.value === 'investing' ? 3 : 4,
        opportunity_type: opportunityTypeCookie.value,
        delivery_term: deliveryTermCookie.value,
        bedrooms_number: bedroomsNumberCookie.value
      })
    } else {
      path += '?' + buildQueryString({ step: 1 })
    }
  }

  return {
    id,
    label: rt(item.label),
    path,
    class: rt(item.class),
    target: item.target
  }
}))

const toggleSideMenu = () => {
  isMenuOpen.value = !isMenuOpen.value
}
</script>

<script lang="ts">
export default {
  name: 'AppHeader'
}
</script>

<template>
  <header
    class="bg-white z-50 transition-all duration-300 h-64 overflow-y-hidden">
    <NavBar
      :items
      :is-side-menu-open="isMenuOpen"
      @on-side-menu-click="toggleSideMenu" />

    <SideMenuMobile
      v-if="isMenuOpen"
      :items
      @on-side-menu-click="toggleSideMenu" />
  </header>
</template>

<i18n src="./../../AppHeader/i18n.json" lang="json"></i18n>
